import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { FirebaseAppProvider } from 'reactfire';
import 'firebase/performance';
import './styles/tailwind.css';
import {UserContextProvider} from "./Context/UserContext";

const firebaseConfig = {
    apiKey: "AIzaSyDB7az0OeqX0kvRw9HKZo_95-Lr0s4qigE",
    authDomain: "chatroom-fe3e3.firebaseapp.com",
    databaseURL: "https://chatroom-fe3e3.firebaseio.com",
    projectId: "chatroom-fe3e3",
    storageBucket: "chatroom-fe3e3.appspot.com",
    messagingSenderId: "55552645730",
    appId: "1:55552645730:web:719247481c9133b85a0f3b"
};

ReactDOM.render(<FirebaseAppProvider firebaseConfig={firebaseConfig} initPerformance>
    <UserContextProvider>
    <App />
    </UserContextProvider>
</FirebaseAppProvider>, document.getElementById('root'));
