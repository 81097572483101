import React from 'react';
import Username from "./Components/Username";
import {UserContext} from "./Context/UserContext";
import Chatroom from "./Components/Chatroom";
import 'firebase/firestore';

function App() {
    const {username} = React.useContext(UserContext);

    if (!username) {
        return <Username/>
    }

    return (
        <Chatroom/>
    )
}

export default App;
