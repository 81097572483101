import React from 'react';
import {UserContext} from "../Context/UserContext";

const Username = (props) => {
    const {setUsername} = React.useContext(UserContext);
    const [username, setUname] = React.useState('');

    return (
        <div className="flex flex-1 bg-gray-200 h-screen">
            <div className="mx-auto my-auto">
                <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                    <div className="px-6 py-4 flex flex-col">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Username
                        </label>
                        <input
                            autoFocus value={username} placeholder="Username"
                               className="shadow appearance-none mb-3 border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline border-blue-300 "
                               onChange={(e) => setUname(e.target.value)}/>
                        <button onClick={(e) => {
                            e.preventDefault();
                            if (!username) {
                                alert('Please enter an username');
                                return;
                            }
                            setUsername(username)
                        }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">Join room</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Username;