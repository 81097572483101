import React from 'react';

const Message = ({message, username, next, previous, isLast}) => {

    const getClassName = () => {
        let className = '';
        if (!next){
            className += 'rounded-br-lg rounded-bl-lg ';
        }
        if (!previous){
            className += 'rounded-tr-lg rounded-tl-lg ';
        }

        return className;
    };
    return (
        <div className={'ml-4 flex flex-col mr-4 ' + (!previous ? 'mt-4' : '')}>

            {!previous ? <div>
                {username}
            </div> : null}
            <div className={'px-4 py-1 flex-wrap bg-white shadow-lg ' + getClassName()}>
                <p className="break-words">{message}</p>
            </div>
        </div>
    )
};

export default Message;