import React, {useState} from 'react';

export const UserContext = React.createContext({
    tutorGroups: {},
});

export const UserContextProvider = (props) => {
    const [username, setUsername] = useState(JSON.parse(localStorage.getItem('username')));
    React.useEffect(() => {
        if (username){
            localStorage.setItem('username', JSON.stringify(username));
        }
    },[username]);
    return (
        <UserContext.Provider value={{
            username,
            setUsername,
        }}>
            {props.children}
        </UserContext.Provider>
    )
};