import React from 'react';
import 'firebase/firestore';
import {useFirebaseApp,} from 'reactfire';
import Message from "./Message";
import {UserContext} from "../Context/UserContext";

const Chatroom = (props) => {
    let input = React.useRef().current;
    let elem = React.useRef().current;
    const {username} = React.useContext(UserContext);
    const firebaseApp = useFirebaseApp();
    const [messages, setMessages] = React.useState([]);
    const [message, setMessage] = React.useState('');
    const messageRef = firebaseApp
        .firestore()
        .collection('rooms')
        .doc('room1')
        .collection('messages');

    const sendMessage = () => {
        input.focus()
        if (!message) {
            return;
        }
        messageRef.add({
            message,
            username: username,
            timestamp: firebaseApp.firestore.Timestamp.fromDate(new Date())
        });
        setMessage('');
        setTimeout(() => window.scrollTo(0,document.body.scrollHeight), 0);
    };
    React.useEffect(() => {
        messageRef.orderBy('timestamp')
            .onSnapshot(function (querySnapshot) {
                var m = [];
                querySnapshot.forEach(function (doc) {
                    let data = doc.data();
                    data.id = doc.id;
                    m.push(data);
                });
                setMessages(m);
            })
    }, []);


    return (
        <div className="flex bg-gray-200 flex-1 min-h-screen h-full flex-col">
            {messages.map((m, i) => {
                let next = false;
                let previous = false;
                if (messages.length > i + 1) {
                    next = messages[i + 1].username === m.username;
                }

                if (i !== 0) {
                    previous = messages[i - 1].username === m.username;
                }

                return <Message key={m.id} next={next} isLast={i +1 === messages.length} previous={previous} username={m.username} message={m.message}/>
            })}
            <div style={{
                height: 100,
            }}/>
            <div className="bottom-0 w-full fixed">
                <div className="flex p-2">
                    <input
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                sendMessage();
                            }
                        }}
                        autoCorrect="off"
                        spellCheck={false}
                        autoCapitalize={false}
                        autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                        autoFocus={true}
                        value={message} placeholder="Type your message..."
                        ref={e => input = e}
                        className="shadow appearance-none mr-2 rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:outline-none border-blue-300 "
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                        onClick={(e) => {
                            sendMessage();
                        }}
                        className="bg-blue-500 hover:bg-blue-700 font-bold py-2 px-2 rounded-full focus:outline-none focus:shadow-outline">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                            <path style={{
                                fill: 'white'
                            }}
                                  d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default Chatroom;